<template>
  <div v-loading="loading">
    <el-card shadow="never">
      <div slot="header">
        <span class="iconfont icon-Staff">&nbsp;员工管理</span>
      </div>
      <div class="card_header_flex">
        <div>
          <el-button type="primary" size="small" @click="dialogAddStaff=true">新增员工</el-button>&nbsp;
          <el-dropdown
            placement="bottom"
            trigger="click"
            @command="onSubmitBatch"
            v-show="dataLineSelections.length>0"
          >
            <el-button size="small" plain>
              批量操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="on">启用</el-dropdown-item>
              <el-dropdown-item command="off">禁用</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>&nbsp;
          <el-button
            size="small"
            @click="onSubmitBatch('del')"
            v-show="dataLineSelections.length>0"
            plain
          >批量删除</el-button>
        </div>
        <div>
          <el-input
            v-model="search"
            placeholder="请输入用户账号、名称"
            suffix-icon="el-icon-search"
            style="width:300px"
            size="small"
            @change="initLoad"
          ></el-input>
        </div>
      </div>
      <el-table
        ref="table"
        :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        size="mini"
        @selection-change="selectionLineChangeHandle"
        row-key="userId"
      >
        <el-table-column
          type="selection"
          width="55"
          :selectable="isChecked"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column prop="mobile" label="账号">
          <template slot-scope="scope">
            <div>
              {{scope.row.mobile}}
              <i
                :class="scope.row.mobile==userInfo.mobile?'el-icon-user-solid':''"
                style="margin-left:10px;color:#5e72f9"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="名称"></el-table-column>
        <el-table-column prop="userMobile" label="联系方式"></el-table-column>
        <el-table-column prop="roleName" label="角色"></el-table-column>
        <el-table-column prop="operateUser" label="操作人"></el-table-column>
        <el-table-column prop="lastUpdateTime" label="最后操作时间"></el-table-column>
        <el-table-column prop="state" label="状态" sortable>
          <template slot-scope="scope">
            <span
              :class="scope.row.state=='on'?'':'closeState'"
            >{{scope.row.state=='on'?'已启用':'已禁用'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="onSubmitSate('off',scope.row)"
              v-if="scope.row.state=='on'"
            >禁用</el-button>
            <el-button type="text" size="small" @click="onSubmitSate('on',scope.row)" v-else>启用</el-button>
            <el-button type="text" size="small" @click="openEdit(scope.row)">编辑</el-button>
            <el-button
              type="text"
              size="small"
              @click="onSubmitDel(scope.row)"
              v-if="scope.row.mobile !=userInfo.mobile"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_right">
        <el-pagination
          background
          layout="total,prev, pager, next ,jumper"
          :total="list.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 新增员工 -->
    <el-dialog
      :visible.sync="dialogAddStaff"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogAddStaff"
    >
      <span slot="title">新增员工</span>
      <el-form
        :model="form"
        ref="form"
        label-width="120px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="账号"
          prop="mobile"
          :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' },{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
        >
          <el-input v-model="form.mobile" maxlength="11" show-word-limit placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item
          label="名称"
          prop="userName"
          :rules="{ required: true, message: '请输入用户名称', trigger: 'blur' }"
        >
          <el-input v-model="form.userName" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item
          label="联系方式"
          prop="userMobile"
          :rules="[{ required: true, message: '请输入联系方式', trigger: 'blur' },{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
        >
          <el-input v-model="form.userMobile" maxlength="11" show-word-limit placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <el-form-item
          label="初始密码"
          prop="password"
          :rules="[{ required: true, message: '请输入初始登录密码', trigger: 'blur' },{ pattern:/^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{6,12}$/,message: '请输入大于6位以上密码，由数字和字母组成',trigger: 'blur'}]"
        >
          <el-input
            v-model="form.password"
            minlength="6"
            maxlength="11"
            show-word-limit
            placeholder="请输入初始登录密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            v-model="form.roleId"
            placeholder="请选择"
            @change="onChangeSelectedRole"
            style="width:100%"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="后台权限">
          <div class="down-tree">
            <el-tree
              :data="showMenuData"
              default-expand-all
              node-key="menuId"
              ref="editTree"
              highlight-current
              :props="{ children: 'subMenu', label: 'name'}"
            ></el-tree>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddStaff=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddStaff">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改员工 -->
    <el-dialog :visible.sync="dialogEditStaff" :close-on-click-modal="false" width="45%">
      <span slot="title">编辑员工</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="120px"
        size="small"
        style="padding:20px"
        :hide-required-asterisk="true"
      >
        <el-form-item label="账号" prop="mobile">
          <p>{{editForm.mobile}}</p>
          <p style="font-size:12px;color:#909399">使用该手机号即可登录酒店后台</p>
        </el-form-item>
        <el-form-item
          label="名称"
          prop="userName"
          :rules="{ required: true, message: '请输入用户名称', trigger: 'blur' }"
        >
          <el-input v-model="editForm.userName" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item
          label="联系方式"
          prop="userMobile"
          :rules="[{ required: true, message: '请输入联系方式', trigger: 'blur' },{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
        >
          <el-input
            v-model="editForm.userMobile"
            maxlength="11"
            show-word-limit
            placeholder="请输入联系方式"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            v-model="editForm.roleId"
            placeholder="请选择"
            @change="onChangeSelectedRole"
            style="width:100%"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="后台权限">
          <div class="down-tree">
            <el-tree
              :data="showMenuData"
              default-expand-all
              node-key="menuId"
              ref="editTree"
              highlight-current
              :props="{ children: 'subMenu', label: 'name'}"
            ></el-tree>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditStaff=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditStaff">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getGroupRoleApi,
  getGroupRoleMenuApi,
  getGroupStaffApi,
  addGroupStaffApi,
  editGroupStaffApi,
  delGroupStaffApi,
  batchEditGroupStaffApi,
  batchDelGroupStaffApi
} from "../../api/data";
import Aes from "../../utils/aes";
export default {
  data() {
    return {
      loading: true,
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      search: "", //查询条件
      list: [],
      dialogAddStaff: false, //新增员工窗口
      dialogEditStaff: false, //修改员工窗口
      form: {
        mobile: "",
        userName: "",
        userMobile: "",
        password: "",
        roleId: ""
      },
      editForm: {},
      showMenuData: [], //显示权限tree集合
      roleOptions: [], //角色下拉菜单
      dataLineSelections: [] //表格选中集合
    };
  },
  computed: {
    //当前场所信息
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    //当前登录用户信息
    userInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.userInfo));
    }
  },
  created() {
    this.initLoad();
    this.initLoadRole();
  },
  methods: {
    //批量操作
    onSubmitBatch(command) {
      if (this.dataLineSelections.length == 0) {
        this.$message.warning("请至少勾选一项");
        return;
      }
      if (command == "on") {
        //启用
        batchEditGroupStaffApi({
          groupId: this.placesInfo.groupId,
          userIds: this.dataLineSelections,
          state: "on"
        }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      } else if (command == "off") {
        //禁用
        batchEditGroupStaffApi({
          groupId: this.placesInfo.groupId,
          userIds: this.dataLineSelections,
          state: "off"
        }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      } else {
        //删除
        this.$confirm("删除后, 以下账号将无法管理场所?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          batchDelGroupStaffApi({
            groupId: this.placesInfo.groupId,
            userIds: this.dataLineSelections
          }).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.$refs.table.clearSelection();
            }
          });
        });
      }
    },

    //初始化员工列表
    initLoad() {
      getGroupStaffApi({
        groupId: this.placesInfo.groupId,
        param: this.search
      }).then(res => {
        this.list = res.data;
        this.loading = false;
      });
    },
    //初始化角色下拉
    initLoadRole() {
      //查询角色名称下拉菜单
      getGroupRoleApi({ groupId: this.placesInfo.groupId }).then(res => {
        this.roleOptions = res.data;
        this.form.roleId = res.data[0].roleId;
        //查询当前选择的角色对应的权限列表
        getGroupRoleMenuApi({ roleId: this.form.roleId }).then(res => {
          this.showMenuData = res.data;
        });
      });
    },
    //选择角色回调
    onChangeSelectedRole(val) {
      getGroupRoleMenuApi({ roleId: val }).then(res => {
        this.showMenuData = res.data;
      });
    },
    //表格多选框选中事件
    selectionLineChangeHandle(val) {
      const selectionList = [];
      for (let i = 0; i < val.length; i++) {
        selectionList.push(val[i].userId);
      }
      this.dataLineSelections = selectionList;
    },
    //提交新增员工
    onSubmitAddStaff() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.groupId = this.placesInfo.groupId;
          addGroupStaffApi(this.form).then(res => {
            console.log(res);
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogAddStaff = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //新增窗口关闭事件
    closeDialogAddStaff() {
      this.$refs["form"].resetFields();
    },
    //启用 禁用开关事件
    onSubmitSate(val, obj) {
      editGroupStaffApi({
        groupId: obj.groupId,
        roleId: obj.roleId,
        userId: obj.userId,
        state: val
      }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //打开修改窗口
    openEdit(obj) {
      this.editForm = { ...obj };
      this.dialogEditStaff = true;
      this.onChangeSelectedRole(this.editForm.roleId);
    },
    //提交修改
    onSubmitEditStaff() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          editGroupStaffApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.dialogEditStaff = false;
              this.initLoad();
            }
          });
        } else {
          return false;
        }
      });
    },
    //删除员工
    onSubmitDel(obj) {
      this.$confirm("删除后, 该账号将无法管理场所?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delGroupStaffApi({ groupId: obj.groupId, userId: obj.userId }).then(
          res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
            }
          }
        );
      });
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    //当前账号不能选中
    isChecked(row) {
      return this.placesInfo.userId == row.userId ? false : true;
    }
  }
};
</script>
<style lang='scss'>
.down-tree {
  height: 200px;
  display: block;
  overflow-y: scroll;
  border: 1px solid #d7dae2;
  border-radius: 4px;
}
.closeState {
  color: #f56c6c;
}
</style>
